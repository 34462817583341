<template>
  <div class="all-box">
    <img class="bg-top" src="@/assets/img/performance/bg-4.png" />
    <img class="bg-bottom" src="@/assets/img/performance/bg-5.png" />
    <div class="foun-top">
      <Breadcrumb class="bread breadcrumb-1400" :list="list"></Breadcrumb>
    </div>
    <div class="foun-box">
      <div class="left">
        <div class="title">{{ detailList.title }}</div>
        <div class="detail">
          <div class="time">{{ detailList.createTime }}</div>
          <div class="line"></div>
          <div class="author">{{ detailList.author }}</div>
          <div class="line"></div>
          <div class="source">{{ detailList.source }}</div>
        </div>
        <div v-html="detailList.body" class="content"></div>
        <div
          v-if="detailList.attachmentInfo && detailList.attachmentInfo.length"
          class="fujian"
        >
          <div class="title1">附件：</div>
          <div class="item1">
            <el-link
              v-for="(item, i) in detailList.attachmentInfo"
              :key="i"
              type="primary"
              :href="item.linkUrl"
              class="link"
              :underline="false"
              >{{ item.originalFileName }}</el-link
            >
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">热点资讯</div>
        <div
          @click="check(item)"
          v-for="item in this.rightList"
          :key="item.id"
          class="content"
        >
          <div class="name">{{ item.name }}</div>
          <div class="time">{{ "发布时间：" + item.createTime }}</div>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>
<script>
import Breadcrumb from "../../../components/pc/Breadcrumb.vue";
import { getPortalDetail } from "@/service/ArtsInformation";
import { getHomePortal } from "@/service/home";
import { formatRichText } from "@/utils/formatRichText";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      list: [],
      id: this,
      detailList: {},
      rightList: [],
    };
  },
  created() {
    this.pageFilter(this.$route.query.page);
    this.getList(this.$route.query.id);
    this.getRight();
  },
  mounted() {
    const isFirstVisit = sessionStorage.getItem("isFirstVisit_B") === null;

    if (isFirstVisit) {
      // 设置标记，表示B页面已经访问过一次
      sessionStorage.setItem("isFirstVisit_B", "false");

      // 执行刷新操作
      window.location.reload();
    }
  },
  methods: {
    check(item) {
      const { href } = this.$router.resolve({
        path: "/ArtsInformation/detail",
        query: {
          id: item.targetId,
          page: "1",
        },
      });
      window.open(href, "_blank");
    },
    addFontSizeToUlTags(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const ulTags = doc.querySelectorAll("span");

      ulTags.forEach(function (span) {
        span.style.fontSize = "0.9375vw";
      });

      return doc.documentElement.innerHTML;
    },
    async getRight() {
      const res = await getHomePortal();
      this.rightList = res.data.referralRight;
    },
    async getList(id) {
      const res = await getPortalDetail(id);
      this.detailList = res.data;
      this.detailList.body = formatRichText(this.detailList.body);
      if (this.detailList.attachmentInfo) {
        this.detailList.attachmentInfo = JSON.parse(
          this.detailList.attachmentInfo
        );
      } else {
        this.detailList.attachmentInfo = [];
      }
      // this.detailList.body = this.addFontSizeToUlTags(this.detailList.body);
    },
    pageFilter(page) {
      if (page == 1) {
        this.list = ["人艺资讯", "剧院动态", "剧院动态详情"];
        return;
      } else if (page == 2) {
        this.list = ["人艺资讯", "剧院公告", "剧院公告详情"];
        return;
      } else if (page == 3) {
        this.list = ["人艺资讯", "剧目推介", "剧目推介详情"];
        return;
      } else if (page == 4) {
        this.list = ["人艺资讯", "人艺活动", "人艺活动详情"];
        return;
      } else {
        this.list = ["资讯", "详情"];
      }
    },
  },
};
</script>
<style scoped lang="less">
.all-box {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f8f8fa;
  margin-top: -1.0417vw;
  z-index: 2;

  .bg-top {
    position: absolute;
    z-index: -1;
  }

  .bg-bottom {
    position: absolute;
    right: 0;
    bottom: 31.25vw;
    z-index: -1;
  }

  .foun-top {
    width: 41.6667vw;
    height: 0.7292vw;
    margin-top: 1.0417vw;
    margin-left: 13.0208vw;

    .bread {
      padding-top: 1.0417vw;
    }
  }

  .foun-box {
    display: flex;
    // width: 1800px;
    margin: 2.0833vw 13.0208vw 2.6042vw 13.0208vw;
    min-height: 52.0833vw;
    height: auto;
    display: flex;
    position: relative;
    /* 添加定位属性 */
    z-index: 5;

    .left {
      width: 52.0833vw;
      background-color: #ffffff;

      .title {
        font-weight: 700;
        font-size: 1.1979vw;
        text-align: left;
        margin-top: 1.8229vw;
        margin-left: 2.6042vw;
        margin-right: 2.6042vw;
      }

      .detail {
        display: flex;
        font-size: 0.7292vw;
        height: 0.7292vw;
        line-height: 0.7292vw;
        margin-left: 2.6042vw;
        margin-top: 0.7813vw;
        color: #666;

        .line {
          height: 0.7292vw;
          width: 0.1042vw;
          background: #666;
          margin-left: 0.2604vw;
          margin-right: 0.2604vw;
        }
      }

      .content {
        font-size: 0.8333vw;
        margin-left: 2.6042vw;
        margin-right: 2.6042vw;
      }
    }

    .right {
      width: 20.8333vw;
      margin-left: 2.6042vw;
      height: 26.0417vw;
      background-color: #ffffff;

      .title {
        text-align: left;
        margin-top: 1.3021vw;
        margin-left: 1.3021vw;
        font-size: 0.8333vw;
        font-weight: 700;
      }

      .content {
        margin-top: 1.3021vw;
        margin-left: 1.3021vw;
        margin-right: 1.3021vw;
        text-align: justify;
        cursor: pointer;

        .name {
          font-weight: 700;
          font-size: 0.8333vw;
        }

        .time {
          margin-top: 0.5208vw;
          font-size: 0.8333vw;
        }
      }
    }
  }
}
.fujian {
  margin-top: 1.8229vw;
  margin-left: 2.6042vw;
  margin-right: 2.6042vw;
  .title1 {
    font-size: 0.7813vw;
  }
  .item1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.7813vw;
    .link {
      text-align: start;
      margin: 0.2604vw 0;
      font-size: 0.7813vw;
    }
  }
}
.el-link {
  display: inline-block;
  justify-content: flex-start;
  align-items: flex-start;
}
</style>
<style>
.content img {
  max-width: 100%;
}
</style>
